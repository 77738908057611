import React, { useEffect, useState } from 'react';
import loader from '../../../assets/images/spinner.svg'
import axios from 'axios';
import './style.scss'
import cross from "../../../assets/images/cross.svg"
// import female from "../../../assets/images/female.svg"

const SuperAdminImageWithoutToken = ({ imgUrl, placeholder = null, isPreview = false, alt="Profile Pic", isApiCall = true, isAnalytics = false, ...rest }) => {

    const [imgSrc, setImgSrc] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        if (imgUrl && imgUrl !== '-' && !isAnalytics)
            getImage(imgUrl)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (imgUrl && imgUrl !== '-' && isAnalytics)
            getImage(imgUrl)
    }, [imgUrl])

    const getImage = imgUrl => {
        if (isApiCall) {
            setIsLoading(true);
    
            // Create an isolated axios instance for the image request
            const axiosInstance = axios.create();
    
            axiosInstance({
                url: imgUrl,
                method: 'GET',
                responseType: 'blob',
                transformRequest: [(data, headers) => {
                    // Remove the authorization header for this request
                    delete headers['authorization'];
                    return data;
                }],
            }).then((res) => {
                const fileUrl = URL.createObjectURL(res.data);
                setImgSrc(fileUrl);
                setIsLoading(false);
            }).catch((err) => {
                // console.log('image error', err);
                setIsLoading(false);
            });
        } else {
            setImgSrc(imgUrl);
        }
    }
    
    

    const handleImageClick = () => {
        !imgError && setShowFullScreen(true);
    };
    const handleCloseFullScreen = () => {
        setShowFullScreen(false);
      };

    if (isLoading && imgUrl) {
        return <img src={loader} alt="loader" className='item-img img-loader' />
    }
    const handleError = (e) => {
        e.target.src = placeholder;
        setImgError(true)
    }
    return <>
        {
            imgSrc ? <img onClick={handleImageClick} height={20} src={imgSrc} fetchpriority="low" alt={alt} onError={handleError} {...rest} />
                : <>
                    {placeholder && <img src={placeholder} {...rest} alt="placeholder" height={22} />}
                </>
        }
        {
           isPreview && showFullScreen && (<div className='image-fullwidth'>
                <span className='close-image' onClick={handleCloseFullScreen}> <img src={cross} alt="close icon" /> </span>
                <img src={imgSrc} {...rest} fetchpriority="low" alt="Full Screen" />
                
            </div>)
        }
    </>
}
export default SuperAdminImageWithoutToken;