import React from 'react';
import unmap from '../../../../assets/images/git-pull-request.svg';
import plusSquare from "../../../../assets/images/plus-square.png";
import EditIcon from "../../../../assets/images/Edit_Icon_blue.png";
import deleteIcon from '../../../../assets/images/delete-icon-red.svg'

const TableColumns = ({ handleDelete, setIsModalOpen, setSelectedRow, getValidationList, handleDeleteValidation }) => {

    const handleAssetCount = (row) => {
        return <span className="activity-list-count asset-count">{row.assets?.length || 0}</span>;
    };

    const handleProductCount = (row) => {
        return <span className="activity-list-count">{row.products?.length || 0}</span>;
    };

    const handleEventCount = (row) => {
        return <span className="activity-list-count">{row.eventsCounts || 0}</span>;
    };

    const handleAssetIncome = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
    };

    return [
        {
            key: "id",
            text: `Livelihood ID`,
            isSort: true
        },
        {
            key: "livelihoodName",
            text: `Livelihood Type`,
            isSort: true
        },
        {
            key: "name",
            text: `Livelihood Name (internal use)`,
            isSort: true
        },
        {
            key: "displayName",
            text: `Display Livelihood Name`,
            isSort: true
        },
        {
            key: "assetCounts",
            text: `Asset Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='livelihood-icon-wrap asset-details' onClick={() => handleAssetIncome(row)}>
                        {handleAssetCount(row)}
                        <img src={plusSquare} alt='plus' />
                    </div>
                );
            }
        },
        {
            key: "productCounts",
            text: `Product Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => {
                return handleProductCount(row);
            }
        },
        {
            key: "eventsCounts",
            text: `Event Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => {
                return handleEventCount(row);
            }
        },
        {
            key: "mappedValidationId",
            text: `Validation`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='livelihood-validation'>
                        {row?.mappedValidationName ? (
                            <span
                            className='livelihood-validation-value'
                        >
                            {truncateText(row?.mappedValidationName, 19)}
                            <div className="livelihood-validation-value-tooltip">{row?.mappedValidationName}</div>
                            <div className='livelihood-validation-edit-icon' onClick={() => getValidationList(row?.id, true)}><img src={EditIcon} alt='Edit' /></div>
                            <div><img src={deleteIcon} alt="delete" height="16" onClick={()=> handleDeleteValidation(row?.mappedValidationName, row?.mappedValidationId, row?.name, row?.id)} /></div>
                        </span>
                        ) : (
                            <span
                                className='livelihood-validation-text'
                                onClick={() => getValidationList(row?.id, false)}
                            >
                                +Add
                            </span>
                        )}
                    </div>
                );
            },
        },
        {
            key: "action",
            text: `Actions`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='livelihood-icon-wrap' onClick={() => handleDelete(row?.id, row?.name)}>
                        <img src={unmap} alt="delete" height="16" /> Un-map
                    </div>
                );
            },
        },
    ];
};

export default TableColumns;
