import React, { useEffect, useState } from 'react'
import './style.scss'
import BlueLeftArrow from '../../../../assets/images/BlueLeftArrow.svg';
import DeleteIcon from '../../../../assets/images/delete-with-background.svg';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../storeManager/modal/actions';
import { MODALS } from '../../../../storeManager/modal/constants';
import CustomModal from '../../../../components/Common/Modal';
import CustomDropdown from '../../../../components/Common/CustomDropdown';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import { setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import SuperAdminImageWithoutToken from '../../SuperAdminImageWithoutToken';
import dummyImg from '../../../../assets/images/defaultLivelihoodIcon.png'
import { handleAuditTrail, logAuditTrail } from '../../../../components/utils/utils';

const CreateLivelihood = ({ handleCreateLivelihoodPage, isEdit, editData, setToastMessage }) => {

    const [livelihoodName, setLivelihoodName] = useState('');
    const [livelihoodDisplayName, setLivelihoodDisplayName] = useState('');
    const [assetType, setAssetType] = useState('');
    const [productType, setProductType] = useState('');
    const [typeEventName, setTypeEventName] = useState('');
    const [selectEventType, setSelectEventType] = useState('');
    const [image, setImage] = useState(null);
    const [imageName, setImageName] = useState(null);
    const [isdefaultImg, setIsdefaultImg] = useState('');
    const [eventListData, setEventListData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [livelihoodId, setLivelihoodId] = useState();
    const [assetDropDownListData, setAssetDropDownListData] = useState([]);
    const [ProductDropDownListData, setProductDropDownListData] = useState([]);
    const [eventNameDropDownListData, setEventNameDropDownListData] = useState([]);
    const [livelihoodNameDropDownListData, setLivelihoodNameDropDownListData] = useState([]);
    const [programLivelihoodName, setProgramLivelihoodName] = useState('');
    const [assetTypeDisplayName, setAssetTypeDisplayName] = useState('');
    const [productTypeDisplayName, setProductTypeDisplayName] = useState('');
    const [assetDetails, setAssetDetails] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [eventDetails, setEventDetails] = useState([]);
    const [livelihoodCategory, setLivelihoodCategory] = useState('');
    const [livelihoodCategoryOptionListData, setLivelihoodCategoryOptionListData] = useState([]);

    const dispatch = useDispatch();

    const eventTypeOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.eventTypeOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                setEventListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setEventListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setEventListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };
    const assetTypeOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.assetTypeOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setAssetDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setAssetDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setAssetDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };
    const productTypeOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.productTypeOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setProductDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setProductDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setProductDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const eventNameOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.eventNameOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setEventNameDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setEventNameDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setEventNameDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const livelihoodNameOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.livelihoodNameOptionList();
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const updatedData = res?.data.map(item => ({
                    ...item,
                    value: item?.name,
                    name: item?.displayName
                }));
                let newupdatedData = updatedData.sort((a, b) => {
                    if (a.name < b.name) {
                      return -1;
                    }
                    if (a.name > b.name) {
                      return 1;
                    }
                    return 0;
                  });
                  setLivelihoodNameDropDownListData(newupdatedData)
            }
            else {
                dispatch(setApiError())
                setLivelihoodNameDropDownListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setLivelihoodNameDropDownListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const livelihoodCategoryOptionList = async () => {
        setIsLoading(true)
        try {
            const response = await commonService.livelihoodCategoryDropdownList(livelihoodName);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                const result = res?.data.map(item => ({
                    name: item,
                    value: item
                }));
                setLivelihoodCategoryOptionListData(result)
            }
            else {
                dispatch(setApiError())
                setLivelihoodCategoryOptionListData([])
            }
            setIsLoading(false)
        } catch (error) {
            setLivelihoodCategoryOptionListData([])
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    useEffect(() => {
        eventTypeOptionList()
        assetTypeOptionList()
        productTypeOptionList()
        eventNameOptionList()
        livelihoodNameOptionList()

        if (isEdit) {
            setAssetDetails(editData?.assets) 
            setProductDetails(editData?.products) 
            setEventDetails(editData?.events)
            setLivelihoodName(editData?.livelihoodName)
            setLivelihoodDisplayName(editData?.displayName)
            setProgramLivelihoodName(editData?.programLivelihoodName)
            setImage(editData?.image)
            setImageName(editData?.image)
            setIsdefaultImg(editData?.image)
            setLivelihoodId(editData?.id)
            setLivelihoodCategory(editData?.category)
        }
    }, [])

    useEffect(()=>{
        livelihoodCategoryOptionList()
    },[livelihoodName])

    const getDisplayName = (arr, name) =>{
        const item = arr?.find(obj => obj.value === name);
        return item ? item.displayName : null;
        }
    const getId = (arr, name) =>{
        const item = arr?.find(obj => obj.value === name);
        return item ? item.id : null;
    }

    const handleAddAssetType = () => {
        const newAddAssetData = {
            "name": assetType,
            "status": 1,
            "displayName": assetTypeDisplayName,
            "type": assetType
        }
        setAssetDetails([...assetDetails, newAddAssetData])
        setAssetTypeDisplayName('')
        setAssetType('');
    };

    const handleAddProductType = () => {
        const newAddProductData = {
            "name": productType,
            "status": 1,
            "displayName": productTypeDisplayName,
            "type": productType
        }
        setProductDetails([...productDetails, newAddProductData])
        setProductType('');
        setProductTypeDisplayName('')
    };

    const handleAddEvent = () => {
        const newEventData = {
            "name": typeEventName,
            "status": 1,
            "displayName": getDisplayName(eventNameDropDownListData, typeEventName),
            "type": selectEventType,
            "eventsTypeId": getId(eventListData, selectEventType),
        }
        setEventDetails([...eventDetails, newEventData])

        setTypeEventName('');
        setSelectEventType('')
    };

    const handleDeleteAssetType = (filteredIndex) => {
        const filteredItems = assetDetails.filter((item) => item.status === 1);
        const selectedItem = filteredItems[filteredIndex];
      
        const updatedArr = assetDetails.map((item) =>
          item === selectedItem ? { ...item, status: 0 } : item
        );
          setAssetDetails(updatedArr); 
    };

    const handleDeleteProductType = (filteredIndex) => {
          const filteredItems = productDetails.filter((item) => item.status === 1);
        const selectedItem = filteredItems[filteredIndex];
      
        const updatedArr = productDetails.map((item) =>
          item === selectedItem ? { ...item, status: 0 } : item
        );
          setProductDetails(updatedArr);
    };

    const handleDeleteEvent = (filteredIndex) => {
        const filteredItems = eventDetails.filter((item) => item.status === 1);
        const selectedItem = filteredItems[filteredIndex];
      
        const updatedArr = eventDetails.map((item) =>
          item === selectedItem ? { ...item, status: 0 } : item
        );
        setEventDetails(updatedArr);
      };

    const capitalizeWords = (sentence) => {
        return sentence
            .replace(/_/g, ' ') 
            .toLowerCase() 
            .replace(/\b\w/g, char => char.toUpperCase());
    }

    function formatEventName(name) {
        let newName = name.replace(/([a-z])([A-Z])/g, '$1 $2')
                    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
        newName = newName.replace(/Po P/g, 'PoP');

        return newName;
      }

    const handleIconUpload = () => {
        dispatch(openModal(MODALS.CREATE_LIVELIHOOD_ICON_UPLOAD_MODAL,
            {
                parentClass: "livelihood-icon-upload center-modal confirmation-modal",
                // confirmAction: confirmAction,
                modalTitle: 'Livelihood Icon',
                setImage: setImage,
                setImageName: setImageName,
                setIsdefaultImg: setIsdefaultImg,
            }))
    }

    const livelihoodSaveUpdate = async (payload) => {
        setIsLoading(true)
        try {
            const response = await commonService.livelihoodSaveUpdate(payload);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                setToastMessage(res?.message)
                handleAuditTrail(res, {
                    actionType: "CREATE",
                    actionStatusType:"SUCCESS",
                    dataChangeDetails: [{
                        "entityId": livelihoodId,
                        "entityName": "Livelihood ID",
                        "fieldName": "Livelihood Name",
                        "oldValue": livelihoodName,
                        "newValue": payload?.name
                    }]
                })
            }
            else {
                dispatch(setApiError())
                setToastMessage('')
            }
            setIsLoading(false)
        } catch (error) {
            setToastMessage('')
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
            setIsLoading(false)
        }
    };

    const handleCreateUpdateLivelihood = () => {
        
        const segments = imageName.split('/');
        const imgName = segments.filter(segment => segment.includes('.png')).pop();
        
        let CreateData = {
            "name": programLivelihoodName,
            "type": livelihoodName,
            "displayName": livelihoodDisplayName,
            "programLivelihoodName": programLivelihoodName,
            "status": 1,
            "image": imgName ? imgName : imageName,
            "assets": assetDetails,
            "products": productDetails,
            "events": eventDetails,
            "eventsCounts": eventDetails.length,
            "category": livelihoodCategory,
        }

        let updateData = {
            "id": livelihoodId,
            "name": programLivelihoodName,
            "type": livelihoodName,
            "displayName": livelihoodDisplayName,
            // "programLivelihoodName": programLivelihoodName,
            // "status": 1,
            "image": imgName ? imgName : imageName,
            "assets": assetDetails,
            "products": productDetails,
            "events": eventDetails,
            "eventsCounts": eventDetails.length,
            "category": livelihoodCategory,
        }

        livelihoodSaveUpdate(isEdit? updateData : CreateData)
        handleCreateLivelihoodPage(false)
    }

    const isButtonDisabled = !(
        livelihoodName && 
        livelihoodCategory &&
        livelihoodDisplayName &&
        programLivelihoodName &&
        imageName &&
        eventDetails.some((item) => item.status !== 0) &&
        (assetDetails.some((item) => item.status !== 0) || productDetails.some((item) => item.status !== 0))
    );

    return (
        <div className='create-livelihood-container'>
            <div className='create-livelihood-body-sec'>
                <div className='create-livelihood-left-sec'>
                    <div className='create-livelihood-header-sec'>
                        <img
                            className='create-livelihood-back'
                            src={BlueLeftArrow}
                            onClick={() => handleCreateLivelihoodPage(false)}
                            height={21}
                        />
                        <span>
                           {isEdit ? "Edit" : "Create"} Livelihood
                        </span>
                    </div>
                    <div className='create-livelihood-input-outer'>
                        <div className='create-livelihood-input-sec'>
                            <label>Livelihood Type <span>*</span></label>
                            <div className='input-group'>
                                <div className='livelihood-select-dropdown'>
                                   <CustomDropdown
                                            // showSearchBar={false}
                                            showSearchBar={true}
                                            placeHolder="Select"
                                            onChange={(value) => setLivelihoodName(value)}
                                            options={livelihoodNameDropDownListData}
                                            value={livelihoodName} />
                                            </div>
                            </div>
                        </div>
                        <div className='create-livelihood-input-sec'>
                            <label>Livelihood Category <span>*</span></label>
                            <div className='input-group'>
                                <div className={`livelihood-select-dropdown ${livelihoodCategoryOptionListData?.length > 0 ? "" : "disabled"}`}>
                                   <CustomDropdown
                                            // showSearchBar={false}
                                            showSearchBar={true}
                                            placeHolder="Select"
                                            onChange={(value) => setLivelihoodCategory(value)}
                                            options={livelihoodCategoryOptionListData}
                                            value={livelihoodCategory}
                                            disabled={livelihoodCategoryOptionListData?.length === 0} />
                                            </div>
                            </div>
                        </div>

                        <div className='create-livelihood-input-sec'>
                            <div className='input-group'>
                                <div className='livelihood-displayName-event-input-outer'>
                                <label>Display Name (livelihood) <span>*</span></label>
                                    <input
                                        className='livelihood-input'
                                        type="text"
                                        value={livelihoodDisplayName}
                                        onChange={(e) => setLivelihoodDisplayName(e.target.value)}
                                    />
                                </div>
                                <div className='livelihood-uploadIcon-event-input-outer'>
                                <label className='icon-upload-label'> <span>*</span></label>
                                <label className='livelihood-icon-upload-label' htmlFor="image-upload">
                                    <div className='livelihood-add' onClick={handleIconUpload}

                                    >

                                        {image ? '+' : '+ Icon'}

                                        {image && (isdefaultImg ?
                                            <SuperAdminImageWithoutToken imgUrl={isdefaultImg} placeholder={dummyImg} isPreview={false} alt='Livelihood img' className='livelihood-icon-img' />
                                            :
                                            <div style={{
                                                backgroundImage: image ? `url(${image})` : '',
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                cursor: 'pointer',
                                                // display: 'inline-block',
                                                textAlign: 'center',
                                                // lineHeight: '150px',
                                                color: image ? 'transparent' : '#555',
                                                width: '20px',
                                                height: '20px',
                                                marginLeft: '5px'
                                            }}></div>)
                                        }
                                    </div>
                                </label>
                                </div>
                            </div>
                        </div>
                        <div className='create-livelihood-input-sec'>
                            <label>Livelihood Name (internal use) <span>*</span></label>
                            <div className='input-group'>
                                <input
                                    className='livelihood-input'
                                    type="text"
                                    value={programLivelihoodName}
                                    onChange={(e) => setProgramLivelihoodName(e.target.value)}
                                />
                            </div>
                        </div>

                            <div className='create-livelihood-input-sec'>
                                <div className='input-group display-name-input-group'>
                                    <div className='livelihood-event-input-outer'>
                                        <span className='livelihood-event-lebel'>Asset Type</span>
                                        <div className='livelihood-select mr5'>
                                            <CustomDropdown
                                                showSearchBar={true}
                                                placeHolder="Select"
                                                onChange={(value) => setAssetType(value)}
                                                options={assetDropDownListData}
                                                value={assetType} />
                                        </div>
                                    </div>
                                    <div className='livelihood-event-input-outer'>
                                        <span className='livelihood-event-lebel'>Asset Type (Display Name)</span>
                                        <div className='livelihood-select'>
                                        <input
                                                className='livelihood-input livelihood-display-name-input'
                                                type="text"
                                                value={assetTypeDisplayName}
                                                onChange={(e) => setAssetTypeDisplayName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                     <button disabled={assetType.length <= 0 || assetTypeDisplayName.length <= 0} className='livelihood-add livelihood-event-add' onClick={handleAddAssetType}>+ Add</button>
                                </div>

                            </div>

                            <div className='create-livelihood-input-sec mt5'>
                                <div className='input-group display-name-input-group'>
                                    <div className='livelihood-event-input-outer'>
                                    <label>Product Type</label>
                                        {/* <span className='livelihood-event-lebel'>Product Type</span> */}
                                        <div className='livelihood-select mr5'>
                                        <CustomDropdown
                                            // showSearchBar={false}
                                            showSearchBar={true}
                                            placeHolder="Select"
                                            onChange={(value) => setProductType(value)}
                                            options={ProductDropDownListData}
                                            value={productType} />
                                        </div>
                                    </div>
                                    <div className='livelihood-event-input-outer'>
                                        <span className='livelihood-event-lebel'>Product Type (Display Name)</span>
                                        <div className='livelihood-select'>
                                        <input
                                    className='livelihood-input livelihood-display-name-input'
                                    type="text"
                                    value={productTypeDisplayName}
                                    onChange={(e) => setProductTypeDisplayName(e.target.value)}
                                />
                                        </div>
                                    </div>
                                    <button disabled={productType.length <= 0 || productTypeDisplayName.length <= 0} className='livelihood-add livelihood-event-add' onClick={handleAddProductType}>+ Add</button>
                                </div>
                            </div>

                        <span className='create-livelihood-event-sec-lebel'>Event <span>*</span></span>
                        <div className='create-livelihood-input-sec'>
                            <div className='input-group'>
                                <div className='livelihood-event-input-outer'>
                                    <span className='livelihood-event-lebel'>Name</span>
                                    <div className='livelihood-select mr5 livelihood-select-event'>
                                        <CustomDropdown
                                            // showSearchBar={false}
                                            showSearchBar={true}
                                            placeHolder="Select"
                                            onChange={(value) => setTypeEventName(value)}
                                            options={eventNameDropDownListData}
                                            value={typeEventName} /></div>
                                </div>
                                <div className='livelihood-event-select-outer'>
                                    <span className='livelihood-event-lebel'>Type</span>
                                    <div className='livelihood-select livelihood-select-event'>
                                        <CustomDropdown
                                            // showSearchBar={false}
                                            showSearchBar={true}
                                            placeHolder="Select"
                                            onChange={(value) => setSelectEventType(value)}
                                            options={eventListData}
                                            value={selectEventType} /></div>
                                </div>
                                <button disabled={selectEventType.length === 0 || typeEventName.length === 0} className='livelihood-add livelihood-event-add' onClick={handleAddEvent}>+ Add</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='create-livelihood-right-sec'>
                    {assetDetails.every((item) => item.status === 0) && productDetails.every((item) => item.status === 0) && eventDetails.every((item) => item.status === 0) ?
                        <div className='no-livelihood-data'>
                            No items added
                        </div> :
                        <>
                            <div className='create-livelihood-selected-item'>
                                {assetDetails.some((item) => item.status !== 0) && <h3>Asset Types</h3>}
                                <div className='create-livelihood-selected-value-wrapper'>
                                    {assetDetails.filter((item) => item.status === 1)
                                        .map((item, index) => (
                                            <div className='livelihood-added-value' key={`${item.name}-${index}`}>
                                                {capitalizeWords(item?.name)} - {capitalizeWords(item?.displayName)}
                                                <img src={DeleteIcon} height={25} onClick={() => handleDeleteAssetType(index)} />
                                            </div>
                                        ))}
                                </div>
                            </div>

                            <div className='create-livelihood-selected-item'>
                                {productDetails.some((item) => item.status !== 0) && <h3>Product Types</h3>}
                                <div className='create-livelihood-selected-value-wrapper'>
                                    {productDetails.filter((item) => item.status === 1)
                                        .map((item, index) => (
                                        <div className='livelihood-added-value' key={`${item.name}-${index}`}>
                                             {capitalizeWords(item?.name)} - {capitalizeWords(item?.displayName)}
                                            <img src={DeleteIcon} height={25} onClick={() => handleDeleteProductType(index)} />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className='create-livelihood-selected-item'>
                                {eventDetails.some((item) => item.status !== 0) && <h3>Events</h3>}
                                <div className='create-livelihood-selected-value-wrapper'>
                                    {eventDetails?.filter((item) => item.status === 1)
                                        .map((item, index) => (
                                        <div className='livelihood-added-value' key={`${item.name}-${index}`}>
                                            {item?.displayName} - {formatEventName(item?.type)}
                                            <img src={DeleteIcon} height={25} onClick={() => handleDeleteEvent(index)} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>}
                </div>
            </div>
            <div className='create-livelihood-button-sec'>
                <button className='secondary-btn livelihood-cancel' onClick={() => handleCreateLivelihoodPage(false)}> Cancel</button>
                <button disabled={isButtonDisabled} className='primary-btn livelihood-create-save' onClick={() => handleCreateUpdateLivelihood()}> {isEdit ? "Save" : "Create"}</button>
            </div>
            <CustomModal />
        </div>
    )
}

export default CreateLivelihood