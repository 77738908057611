import React from 'react';

const TableColumns = (setIsModalOpen, setSelectedRow) => {
    const handleActivityList = (row, cell) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleAssetCount = (row, cell) => {
        return <span className="activity-list-count">{row.assets.length}</span>;
    };

    const handleProductCount = (row, cell) => {
        return <span className="activity-list-count">{row.products.length}</span>;
    };

    const handleEventCount = (row, cell) => {
        return <span className="activity-list-count">{row.events.length}</span>;
    };

    return [
        {
            key: "id",
            text: `Livelihood ID`,
            isSort: true
        },
        {
            key: "livelihoodName",
            text: `Livelihood Type`,
            isSort: true
        },
        {
            key: "name",
            text: `Livelihood Name (internal use)`,
            isSort: true
        },
        {
            key: "displayName",
            text: `Display Livelihood Name`,
            isSort: true
        },
        {
            key: `assetCounts`,
            text: `Asset Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => handleAssetCount(row, cell)
        },
        {
            key: "productCounts",
            text: `Product Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => handleProductCount(row, cell)
        },
        {
            key: "eventsCounts",
            text: `Event Count`,
            isSort: true,
            formatter: (cell, row, rowIndex) => handleEventCount(row, cell)
        },
        {
            key: "activityName",
            text: `Action`,
            isSort: false,
            formatter: (cell, row, rowIndex) => (
                <div className='view-details' onClick={() => handleActivityList(row, cell)}>View</div>
            )
        },
    ];
};

export default TableColumns;
