import React from "react";
import {
  Route, Routes, BrowserRouter as Router, Navigate
} from "react-router-dom";
import WrapperBoundary from "./components/Common/ErrorBoundary";
import PageNotFound from "./components/Common/PageNotFound";
import PrivateRoute from "./protectedRoute";
import { isSuperAdmin, isUserManager } from "./services/commonUtils";
import { userRoles as roles } from "./config/roles";
import BeneficiaryDetailPage from "./pages/BeneficiaryDetailPage";
import SuperAdmin from "./pages/SuperAdmin";
import ProgramDetailsPage from "./pages/SuperAdmin/ProgramDetailsPage";
import GenericDashboard from "./pages/GenericDashboard";
import AnalyticsDashboard from "./pages/AnalyticsDashboard";
import DownloadComp from "./components/Common/DownloadComp";
import DownloadsFlow from "./pages/DownloadsFlow";

const Login = React.lazy(() => import("./pages/Authentication/Login"));
const LoginNew = React.lazy(() => import("./pages/Authentication/Login/loginNew"));
const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const BaselineDashboard = React.lazy(() => import("./pages/BaselineDashboard"));
const GrantDashboard = React.lazy(() => import("./pages/GrantDashboard"));
const ApprovalPage = React.lazy(() => import("./pages/ApprovalPage"));
const BpcApprovalPage = React.lazy(() => import("./pages/BpcApprovalPage"));
const BeneficiariesPage = React.lazy(() => import("./pages/BeneficiaaryPage"));
const UserProfile = React.lazy(() => import("./pages/ProfilePage"));
const ServerError = React.lazy(() => import("./pages/ServerError"));
const UserManagement = React.lazy(() => import("./pages/UserManagement"));
const VillageManagement = React.lazy(() => import("./pages/UserManagement/VillageManagement"));
const BackupFileUpload = React.lazy(() => import("./pages/UserManagement/BackupFileUpload"));
const PrivacyPolicy = React.lazy(() => import("./components/PrivacyPolicy/index"));
const LivelihoodData = React.lazy(() => import("./pages/LivelihoodData"));
const AppRoutes = () => {

  const userIsAdmin = isUserManager();
  const userIsSuperAdmin = isSuperAdmin();

  return (
    <React.Suspense fallback={
      <div className='preloader'>
        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      </div>
    }>
      <WrapperBoundary>
        <Router>
          <Routes>
            {/* <Route path='/' element={<Login />} /> */}
            <Route path='/' element={<LoginNew />} />
            <Route path='/profile' element={
              <PrivateRoute >
                <UserProfile />
              </PrivateRoute>
            } />

            <Route path='/user-management' element={
              <PrivateRoute userRoles={[roles?.userManager]} >
                <UserManagement />
              </PrivateRoute>
            } />
            <Route path='/village-management' element={
              <PrivateRoute userRoles={[roles?.userManager]} >
                <VillageManagement />
              </PrivateRoute>
            } />
             <Route path='/backup-file-upload' element={
              <PrivateRoute userRoles={[roles?.userManager]} >
                <BackupFileUpload />
              </PrivateRoute>
            } />
             <Route path='/super-admin' element={
              <PrivateRoute userRoles={[roles?.SuperAdmin]} >
                <SuperAdmin />
              </PrivateRoute>
            } />
              <Route path='/super-admin/program-details' element={
              <PrivateRoute userRoles={[roles?.SuperAdmin]} >
                <ProgramDetailsPage />
              </PrivateRoute>
            } />
            <Route path='/dashboard' element={
              <PrivateRoute 
              userRoles={[
                  roles?.BPC,
                  roles?.BPM,
                  roles?.SPM,
                  roles?.SPC,
                  roles?.DPC,
                  roles?.DPM,
                  roles?.NPM,
                  ]}>
                <Dashboard />
              </PrivateRoute>
            } />
            <Route path='/baseline-dashboard' element={
              // <PrivateRoute 
              // userRoles={[
              //     roles?.BPC,
              //     roles?.BPM,
              //     roles?.SPM,
              //     roles?.DPC,
              //     roles?.NPM,
              //     ]}>
              // </PrivateRoute>
                <BaselineDashboard />
            } />
             <Route path='/grant-dashboard' element={
              // <PrivateRoute 
              // userRoles={[
              //     roles?.BPC,
              //     roles?.BPM,
              //     roles?.SPM,
              //     roles?.DPC,
              //     roles?.NPM,
              //     ]}>
              // </PrivateRoute>
                <GrantDashboard />
            } />
             <Route path="/generic-dashboard/:id" element={
                <GenericDashboard/>
            } />
            <Route path="/analytics" element={
               <PrivateRoute 
               userRoles={[
                   roles?.BPC,
                   roles?.BPM,
                   roles?.SPM,
                   roles?.SPC,
                   roles?.DPC,
                   roles?.DPM,
                   roles?.NPM,
                   ]}>
                 
                <AnalyticsDashboard/>
                </PrivateRoute>
            } />
            <Route path='/bpc-approval' element={
              <PrivateRoute userRoles={[roles?.BPC, roles?.SPM,
                roles?.SPC,
                roles?.DPC,
                roles?.DPM
                ]}>
                <BpcApprovalPage />
              </PrivateRoute>
            } />
            <Route path='/bpm-approval' element={
              <PrivateRoute userRoles={[roles?.BPM]}>
                <ApprovalPage />
              </PrivateRoute>
            } />
            <Route path='/beneficiaries' element={
              <PrivateRoute userRoles={[roles?.BPC,
                roles?.BPM,
                roles?.SPM,
                roles?.SPC,
                roles?.DPC,
                roles?.DPM,
                roles?.NPM]}>
                <BeneficiariesPage />
              </PrivateRoute>
            } />
            <Route path='/beneficiaries/beneficiary-details/:id' element={
              <PrivateRoute userRoles={[roles?.BPC,
              roles?.BPM,
              roles?.SPM,
              roles?.SPC,
              roles?.DPC,
              roles?.DPM,
              roles?.NPM]}>
                <BeneficiaryDetailPage />
              </PrivateRoute>
            }/>
            <Route path='/livelihood-data' element={
              <PrivateRoute userRoles={[roles?.BPC,
                roles?.BPM,
                roles?.SPM,
                roles?.SPC,
                roles?.DPC,
                roles?.DPM,
                roles?.NPM]}>
                <LivelihoodData />
              </PrivateRoute>
            } />
            <Route path='/downloads-flow' element={
              <PrivateRoute userRoles={[roles?.BPC,
                roles?.BPM,
                roles?.SPM,
                roles?.SPC,
                roles?.DPC,
                roles?.DPM,
                roles?.NPM]}>
                <DownloadsFlow />
              </PrivateRoute>
            } />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/server-error" element={<ServerError />} />
            <Route path="*" element={<Navigate to={userIsAdmin ? '/user-management' : userIsSuperAdmin ? '/super-admin' : '/dashboard'} />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path='/download' element={
              <PrivateRoute userRoles={[roles?.BPC,
                roles?.BPM,
                roles?.SPM,
                roles?.SPC,
                roles?.DPC,
                roles?.DPM,
                roles?.NPM]}>
                <DownloadComp />
              </PrivateRoute>
            } />

          </Routes>
        </Router>
      </WrapperBoundary>
    </React.Suspense >
  );
}

export default AppRoutes; 
