import React, { useEffect, useState } from 'react'
import UploadFile from '../../UploadFile'
import { useDispatch } from 'react-redux';
import { ModalBody, ModalFooter } from 'reactstrap';
import { commonService } from "../../../../services/common";
import GlobalConfig from '../../../../config/globalconfig';
import { resetApiError, setApiError } from '../../../../storeManager/common/actions';
import { catchErrorHandler } from '../../../../services/commonUtils';
import errorIcon from '../../../../assets/images/red-info-icon.svg';
import InProcessing from '../../InProcessing';
import axios from 'axios';
import './index.scss';
import ToastMsg from '../../ToastMsg';

const SuperAdminProgramDownloadModal = ({ modalParameters: { userType, bodyMsg, programId}, closePopup }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const [apiErrorMsg, setApiErrorMsg] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [dataUploadPercentage, setDataUploadPercentage] = useState();
    const [resData,setResData] = useState('');
    const [formInfo, setFormInfo] = useState({
        id: 'users',
        value: '',
        accept: '.xls, .xlsx',
        fileName: '',
        isError: false,
        errorMsg: ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetApiError())
    }, [])

    const handleChange = (e) => {
        const updatedForm = { ...formInfo };
        // console.log("e.currentTarget.files[0].type",e.currentTarget.files[0].type)
        setApiErrorMsg('')
        updatedForm.fileName = e.currentTarget.files.length > 0 && e.currentTarget.files[0].name;
        updatedForm.value = e.currentTarget.files[0]
        // if ((e.currentTarget.files[0].size / 1024).toFixed(4) > 500) {
        //     updatedForm.errorMsg = `File size exceeds the max allowed size`
        //     updatedForm.isError = true
        // } else 
        if (e.currentTarget.files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            && e.currentTarget.files[0].type !== 'application/vnd.ms-excel') {
            updatedForm.errorMsg = `File Format do not support`
            updatedForm.isError = true
        }
         else {
            updatedForm.errorMsg = ``
            updatedForm.isError = false
        }
        setFormInfo(updatedForm);
    }

    const errorMessage = (res) => {
        if (res?.data.length > 0) {
            return <ul style={{ marginLeft: '15px' }}>
                {res?.data.map((item, index) => (
                    <li key={index}>{item}</li>
                 ))}
            </ul>;
        } else if (res?.message) {
            return res?.message;
        } else {
            return 'Some Error Occured';
        }
    }

    const onSubmit = () => {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('file', formInfo?.value);
        formData.append('programId', programId);
         axios.post(`${GlobalConfig.API_ROOT}/write-api/beneficiary/upload/didi-program-mapping`, formData, {
            onUploadProgress: (progressEvent) => {
               setDataUploadPercentage((progressEvent.progress * 100).toFixed(1))
            }
       }).then((response) => {
        const res = response.data;
        if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
               setIsSuccess(true);
               setResData(res);
           } else {
               setApiErrorMsg( errorMessage(res) )
               dispatch(setApiError())
           }
           setIsLoading(false)
       })
           .catch((error) => {
               setIsLoading(false)
               console.error(error)
               catchErrorHandler(error, dispatch(setApiError()));
           });
    }
    const handleDelete = () => {
        const updatedForm = { ...formInfo };
        updatedForm.fileName = '';
        updatedForm.errorMsg = ``;
        updatedForm.isError = false;
        updatedForm.value = '';
        setApiErrorMsg('')
        setFormInfo(updatedForm);
    }

    if (isLoading) {
        return <InProcessing title="File uploading is inprogress" uploadPercentage={Math.trunc(dataUploadPercentage)} maxPrct={100} />
    }

    return (
        <div className='upload-modal'>
            {isSuccess ?
                <ModalBody>
                    <h3>File Uploaded Successfully</h3>
                    {resData && <div className='success-msg'>{resData.message}</div>}
                     <>
                    {resData?.data?.length > 0 && resData?.data[0]?.length > 0 &&
                                resData?.data.slice(0, 50).map((item, key) => {
                                    return (
                                    <>
                                      <div className='error-msg additional-msg' index={key}>
                                        <div className='error-number admin-err-msg'>{`${key+1})`}</div>
                                        <div>{item}</div>
                                      </div>
                                    </>
                                    )
                                })
                    }
                    </>
                <ToastMsg otpMsg={resData.message} success={resData.status  === 'SUCCESS' ? true : false} page={'admin'}/>
                </ModalBody>
                : <ModalBody> {
                    <><h4>{bodyMsg}</h4>
                    <br />
                    <UploadFile
                        id={formInfo.id}
                        value={formInfo.value}
                        accept={formInfo.accept}
                        handleChange={e => handleChange(e)}
                        fileName={formInfo.fileName}
                        isError={formInfo.isError}
                        errorMsg={formInfo.errorMsg}
                        key={formInfo.id}
                        handleDelete={handleDelete}
                    />
                    <ul className='upload-note'>
                        {/* <li>Max upload file size: 500 KB</li> */}
                        <li>Accepted format: .xlsx</li>
                    </ul> </>}
                </ModalBody>}
            {apiErrorMsg && <div className='error-msg ph-20'><img src={errorIcon} width='12' alt="error" />{apiErrorMsg}</div>}
            <ModalFooter>
                {isSuccess ? <>
                    <button className="primary-btn" onClick={closePopup}>
                        OK
                    </button>
                </>
                    :
                    <>
                        <button className="secondary-btn ml" onClick={closePopup}>
                            Cancel
                        </button>
                        <button className="primary-btn" onClick={onSubmit} disabled={formInfo.isError || formInfo.value === ''}>
                            Submit
                        </button>
                    </>
                }
            </ModalFooter>
        </div>
    )
}

export default SuperAdminProgramDownloadModal