import React from 'react';
import unmap from '../../../../assets/images/git-pull-request.svg';
import plusSquare from "../../../../assets/images/plus-square.png";
import EditIcon from "../../../../assets/images/Edit_Icon_blue.png";
import deleteIcon from '../../../../assets/images/delete-icon-red.svg'

const TableColumns = ({handleUnmap}) => {

 

    return [
        // {
        //     key: "id",
        //     text: `Language ID`,
        //     isSort: true
        // },
        {
            key: "langCode",
            text: `Language Code`,
            isSort: true
        },
        {
            key: "language",
            text: `Language`,
            isSort: true
        },
        {
            key: "action",
            text: `Actions`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className={row?.langCode == 'en' ? 'language-icon-wrap icon-disabled' : 'language-icon-wrap'} onClick={() => handleUnmap(row?.langCode)} >
                        <img src={unmap} alt="delete" height="16" /> Un-map
                    </div>
                );
            },
        },

    ];
};

export default TableColumns;
