import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Header from "../../components/Common/Header";
import WidgetSlider from "../../components/WidgetSlider";
import "../Dashboard/style.css";
import "./style.scss";
import FallbackComp from "../../components/Common/FallbackComp";
import { CHECK_API_ENV } from "../../config/apipath";
import axios from "axios";
import SupersetDashboard from "./SupersetDashboard";
import PreLoader from "../../components/Common/Preloader";
import UPAJ from "./UPAJ";
import { commonService } from "../../services/common";

const AnalyticsDashboard = () => {
  const { isLeftArrow, isRightArrow } = useSelector((state) => state.commonReducer);
  const userAuth = JSON.parse(localStorage.getItem("userAuth")) || {};
  const { identityNumber, roleName } = userAuth;

  const [dashboardConfigs, setDashboardConfigs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [widgetChange, setWidgetChange] = useState(null);
  const [widgetIsCollapsed, setWidgetIsCollapsed] = useState(true);
  const [slide, setSlide] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sectionToggle, setSectionToggle] = useState("Chart View");

  const childRef = useRef();

  const fetchDashboards = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await commonService.fetchAnalyticsDashboard(identityNumber, CHECK_API_ENV);
      if (response.data.status === "success") {
        const dashboards = response.data.dashboards_list;
        setDashboardConfigs(dashboards);
        if (dashboards.length > 0) {
          setWidgetChange({ ...dashboards[0], description: "Dashboard from Superset" });
        }
      } else {
        throw new Error("Failed to load dashboards");
      }
    } catch (error) {
      setApiError("Error loading dashboards");
    } finally {
      setIsLoading(false);
    }
  }, [identityNumber]);

  const handleDataRefresh = async (apiUrl) => {
    try {
      const response = await axios.get(apiUrl);
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchDashboards();
  }, [fetchDashboards]);

  const widgetArray = dashboardConfigs.map((dashboard) => ({
    ...dashboard,
    description: "Dashboard from Superset",
    content:
      dashboard.type === "iframe" ? (
        <UPAJ embed_url={dashboard?.embedOptions?.embed_url} name={dashboard?.name} />
      ) : (
        <SupersetDashboard slide={slide} widgetIsCollapsed={widgetIsCollapsed} dashboardConfig={dashboard} />
      ),
  }));

  const dashComponent = widgetArray.find((i) => i.id === widgetChange?.id);

  if (apiError) return <div className="error">Error: {apiError}</div>;

  return (
    <div className="main-page analytics-dashboard fixed-header">
      <Header />
      <div className="main-section">
        {isLoading ? (
          <PreLoader />
        ) : (
          <>
            <div className={`content-section ${widgetIsCollapsed ? "content-section-collapsed" : "content-section-expanded"}`}>
              <div className="map-section-outer">
                <div className={`${dashComponent?.type === "map" ? "map-section h550" : "map-section"} ${slide ? "full-screen-map" : ""}`}>
                  <div className="map-section-head">
                    <div className="map-top-left">
                      <div className="map-title">{dashComponent?.name || "Dashboard"}</div>
                    </div>
                  </div>
                  {(sectionToggle.includes("Map") || sectionToggle.includes("Summary") || sectionToggle.includes("Chart")) && (
                    <div className={slide ? "map-section-body analytics-dashboard-body" : "jc-c map-section-body analytics-dashboard-body"}>
                      {!isError && dashComponent ? dashComponent.content : <div>Select a dashboard</div>}
                      {isError && <FallbackComp />}
                      {dashComponent?.dataRefreshUrl &&  <div className="analytics-refresh-btn-outer"><button onClick={() => handleDataRefresh(dashComponent?.dataRefreshUrl)} class="primary-btn analytics-refresh-btn">Initiate data refresh </button></div>}
                    </div>
                  )}
                </div>
                {isLeftArrow && (
                  <button
                    type="button"
                    className="slick-arrow slick-prev dashboard-button"
                    onClick={() => childRef.current?.dashboardPrev()}
                  ></button>
                )}
                {isRightArrow && (
                  <button
                    type="button"
                    className="slick-arrow slick-next dashboard-button"
                    onClick={() => childRef.current?.dashboardNext()}
                  ></button>
                )}
              </div>
            </div>
            <div className="slider-section">
              <WidgetSlider
                setIsError={setIsError}
                setWidgetChange={setWidgetChange}
                widgetChange={widgetChange}
                setSlide={setSlide}
                setStateName={() => {}}
                setDistrictName={() => {}}
                setToggleType={() => {}}
                setSummary={() => {}}
                type={dashComponent?.type}
                roleName={roleName}
                setSummaryRole={() => {}}
                setQuestion={() => {}}
                setShowExcelCta={() => {}}
                setSourceData={() => {}}
                setSectionToggle={setSectionToggle}
                ref={childRef}
                widgetType="analytics"
                setWidgetIsCollapsed={setWidgetIsCollapsed}
                widgetIsCollapsed={widgetIsCollapsed}
                widgetArray={widgetArray}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyticsDashboard;
