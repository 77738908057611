
import downloadIcon from '../../assets/images/download-icon-deep-blue.svg'
import approve from '../../assets/images/green-dot.png'
import reject from '../../assets/images/red-dot.png'

const TableColumns = ({handleDownload}) => {

    const modifyYesNoSymbol = str => {
        switch (str) {
            case 'Completed':
                return <div className='download-flow-status-wrap'>
                    {/* <img src={approve} width={18} height={18} alt='icon' />  */}
                    {str}
                    </div>
            case 'Started':
                return <div className='download-flow-status-wrap'>
                    {/* <img src={reject} width={18} height={18} alt='icon' /> */}
                     "Inprogress"
                     </div>
            default:
                return '--'
        }
    }
    const handleDateformat = (_date) => {
        const parsedDate = new Date(_date);
        if (isNaN(parsedDate.getTime())) {
            return "Invalid Date";
        }
        const formattedDate = parsedDate.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        });
    
        return formattedDate.replace(/am|pm/i, (match) => match.toUpperCase());
    };
    

    return [
        {
            text: `S.No`,
            formatter: (cell, row, rowIndex) => {
                return (
                rowIndex + 1
                )
            },
        },
        {
            key: "reportName",
            text: `File name`,
            isSort: true
        },
        {
            key: "reportDate",
            text: `Date & Time`,
            formatter: (cell, row, rowIndex) => {
                return handleDateformat(cell)
            },
            isSort: false
        },
        {
            key: "noOfRecord",
            text: `No.of records`,
            formatter: (cell) => {
                return cell !== null ? cell : '--';
            },
            isSort: true
        },
        {
            key: "status",
            text: `Download Status`,
            formatter: (cell, row, rowIndex) => {
                return modifyYesNoSymbol(cell)
            },
            isSort: true
        },
        {
            key: "action",
            text: `Action`,
            isSort: false,
            formatter: (cell, row, rowIndex) => {
                return (
                    <> {row?.status === "Completed" ?
                    <div className='download-flow-download-icon-wrap' 
                    onClick={() => handleDownload(row?.requestId, row?.reportName)}
                    >
                        <img src={downloadIcon} alt="delete" height="16" />
                         <span> Download </span>
                    </div>
                    : "--"
                    }
                    </>
                );
            },
        },

    ]
}
export default TableColumns;