import React, { useEffect, useState } from 'react';
import { ModalBody , ModalFooter} from 'reactstrap';
import './style.scss';
import SearchBar from '../../../SearchBar';
import { commonService } from '../../../../services/common';
import GlobalConfig from '../../../../config/globalconfig';
import { useDispatch , useSelector} from 'react-redux';
import { setApiError, showLoaderAction } from '../../../../storeManager/common/actions';
import { catchErrorHandler, debounce } from '../../../../services/commonUtils';
import Table from '../../Table';
import TableColumns from './columns';


const ProgramLanguageMappingModal = ({modalParameters : {programLanguageMapUnmap, detail }, closePopup}) => {
   
    const dispatch = useDispatch();
    const { isApiError } = useSelector(state => state.commonReducer)

    const [selectedLang, setSelectedLang] = useState([]);

    const [tableData, setTableData] = useState([])
    const [langSearch, setLangSearch] = useState('');
    const [langSearchedList, setLangSearchedList] = useState([]);

    
    useEffect(() => {
       
        if (detail?.programId) {
            fetchLanguageList(detail?.programId)
        }
    }, [])

    const handleSearchFilter = (value) => {

        setLangSearch(value)
        const filteredList = tableData.filter(x => x.language.toLowerCase().includes(value.toLowerCase()))
        setLangSearchedList(filteredList)
    }

    const fetchLanguageList = async (id) => {
        dispatch(showLoaderAction(true));
        try {
            const response = await commonService.unmappedLanguages(detail?.programId);
            const res = response?.data;
            if (res?.status === GlobalConfig.MESSAGES_TYPES.S3_SUCCESS) {
                let updatedList = res?.data;

                
                setTableData(updatedList)
                
            }
            else
                dispatch(setApiError())
            dispatch(showLoaderAction(false));

        } catch (error) {
            dispatch(showLoaderAction(false));
            console.error(error)
            catchErrorHandler(error, dispatch(setApiError()));
        }
    };


    const handleTableSelect = (e, value, isAllSelected = false) => {
        let UpdatedSelectedLang = [...selectedLang];
        if (isAllSelected) {
            const  list = [];
            value.forEach(el => {
                list.push(el.langCode)
            })
            setSelectedLang(list)
        }
        else {
            if (e.target.checked)
                UpdatedSelectedLang.push(value.langCode);
            else
                UpdatedSelectedLang = UpdatedSelectedLang.filter(o => o !== value.langCode);
            setSelectedLang(UpdatedSelectedLang)
        }

    }

    const handleConfirm = () => {
        if(programLanguageMapUnmap) {
            programLanguageMapUnmap(selectedLang, true)
        }
        closePopup();
    }
    

    return <div>
        <ModalBody>
            <>
                <div>
                    Program Name: <b>{detail?.programName}</b> | Program ID: <b>{detail?.programId}</b> | State: <b>{detail?.state}</b>
                </div>
                <div className="search-options">
                    <p className='search-text'>Search</p>
                    <SearchBar className="language-search-bar" placeholder='Search language'  handleOnChange={debounce((e) => handleSearchFilter(e.target.value), 500)}
                />
                </div>
                <div className='mapping-list-outer'>
                    {tableData.length > 0 ?
                         

                            <Table
                                className='pending-table mapping-list'
                                columns={TableColumns()}
                                data={langSearch ? langSearchedList : tableData}
                                checkbox={true}
                                valueKey='langCode'
                                selectedValues={selectedLang}
                                selectAll={false}
                                handleChange={handleTableSelect}
                                // disabled={inputValue.length > 0 ? true : false}
                            />
                    
                        :
                        <div className='no-data'><p>{ "No more unmapped languages found" }</p></div>
                    }
                </div>
            </>
        </ModalBody>
        <ModalFooter>
            <button className="secondary-btn ml" onClick={closePopup}>
                Cancel
            </button>
            <button className="primary-btn" onClick={handleConfirm} >
                Confirm
            </button>
        </ModalFooter>
    </div>

};

export default ProgramLanguageMappingModal;
