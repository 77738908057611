
const TableColumns = (handleRadioChange, selectedRow) => {

    return [
        {
            key: 'radio',
            text: '', // Empty header for the radio column
            formatter: (cell, row, rowIndex) => {
                return (
                    <input
                        type="radio"
                        name="validationRadio"
                        checked={selectedRow === row.id}
                        onChange={() => handleRadioChange(row)}
                    />
                );
            },
          },
          {
            key: "configName",
            text: `Validation Name`,
            isSort: true
        },
        {
            key: "livelihoodName",
            text: `Livelihood Category`,
            isSort: true
        }   
    ];
};

export default TableColumns;
