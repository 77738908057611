import React from 'react'
import './style.scss';

const UPAJ = ({embed_url, name}) => {
  return (
    <div className="upaj-iframe-outer-container">
    <iframe
      src={embed_url}
      className="upaj-iframe-content"
      title={name}
    />
  </div>
  )
}

export default UPAJ