import React, { useEffect } from 'react';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { commonService } from '../../../services/common';

const SupersetDashboard = ({ widgetIsCollapsed, dashboardConfig, slide }) => {
  const containerId = `superset-container-${dashboardConfig?.id || 'default'}`;

  useEffect(() => {
    if (dashboardConfig && dashboardConfig.embedOptions) {
      embedSupersetDashboard(dashboardConfig.id, dashboardConfig.embedOptions);
    }
  }, [dashboardConfig]);

  async function embedSupersetDashboard(dashboardId, embedOptions) {
    try {
      const base_url = embedOptions.supersetDomain;
      const response = await commonService.supersetApiv2(base_url, dashboardId);
      const token = response?.data?.token;
      
      if (!token) {
        console.error("Failed to get guest token for dashboard", dashboardId);
        return;
      }

      const embedConfig = {
        ...embedOptions,
        mountPoint: document.getElementById(containerId),
        fetchGuestToken: () => token,
      };

      embedDashboard(embedConfig);

      setTimeout(() => {
        const iframe = document.querySelector(`#${containerId} iframe`);
        if (iframe) {
          iframe.style.width = widgetIsCollapsed ? '69vw' : "92vw";
          iframe.style.minHeight = '75vh';
        }
      }, 100);

    } catch (error) {
      console.error(`Error embedding dashboard ${dashboardId}:`, error);
    }
  }

  useEffect(() => {
      const iframe = document.querySelector(`#${containerId} iframe`);
      if (iframe) {
        iframe.style.width = widgetIsCollapsed ? '69vw' : "92vw";
        iframe.style.minHeight = '75vh';
      }
  }, [widgetIsCollapsed]);

  return (
    <div className="App superset-dashboard">
      <div id={containerId}></div>
    </div>
  );
}

export default SupersetDashboard;
