import React, { useEffect, useState, useRef } from "react";
import Header from "../../components/Common/Header";
import CustomDropdown from "../../components/Common/CustomDropdown";
import WidgetSlider from "../../components/WidgetSlider";
import layer from "../../assets/images/layers.svg";
import mapLayer from "../../assets/images/map.svg";
import vo from "../../assets/images/VO.svg";
import usericon from "../../assets/images/user-check.svg";
import users from "../../assets/images/users.svg";

import downloadActive from "../../assets/images/dld-active.svg";
import download from "../../assets/images/Icon feather-download.svg";
import info from "../../assets/images/info.svg";
import back from "../../assets/images/back.svg";
import minimize from "../../assets/images/minimize.png";
import { useParams, useLocation } from "react-router-dom";
import maximize from "../../assets/images/maximize.svg";
import PreLoader from "../../components/Common/Preloader";
import "./style.scss";
import SourceData from "./dependency/SourceData";
import SearchBar from "../../components/SearchBar";
import {
  sectionToggleOptions,
  mapDataDropDown,
  getboxData,
  getSourceDataMapping,
  summaryTransformer
} from "./dependency/conifg";
import InfoBox from "./dependency/InfoBox";
import { dashBoardService } from "../Dashboard/dashoardService";

import SummaryData from "./dependency/SummaryData";
import { useDispatch, useSelector } from "react-redux";
import { setRefValue, setRoleValue, showLoaderAction, setActiveGeoFilters, setCurrentGeoRef } from "../../storeManager/common/actions";

import { catchErrorHandler, getLocalStorage, setLocalStorage, sortByKey } from '../../services/commonUtils';

import NoDataFound from "../../components/Common/NoDataFound";

import { defaultWidgetChange, transformWidgetData, getMissionById } from "./utils.js";

import GenericBreadcrumb from "../../components/GenericBreadcrumb/index.js";
import HorizontalChartView from "./dependency/HorizontalChartView/index.js";
import { getDisplayName } from "../../components/utils/utils.js";
import FallbackComp from "../../components/Common/FallbackComp/index.js";

const GenericDashboard = () => {

  const { id: missionId } = useParams();
  const dispatch = useDispatch();

  const { referenceValue, roleValue, isLeftArrow, isRightArrow, activeGeoFilter, showLoader : isLoading } = useSelector((state) => state.commonReducer);

  const { roleName, referenceId: refId } =
    JSON.parse(localStorage.getItem("userAuth")) || "";

  const refobj = refId?.map((i) => {
    return {
      value: i.name,
      id: i.id,
    };
  });

  const [mission, setMission] = useState({})

  const [btntooltip, setBtnTooltip] = useState('');
  const [showExcelCta, setShowExcelCta] = useState(false);
  const [stageType, setStageType] = useState("Wealth Ranking");
  const [summaryRole, setSummaryRole] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showBack, setShowBack] = useState(false);
  const [summary, setSummary] = useState([]);
  const [summaryMapValue, setSummaryMapValue] = useState({});
  const [showLoader, setshowLoader] = useState(true);
  const [stateName, setStateName] = useState("");
  const [districtName, setDistrictName] = useState("");
  const [toggleType, setToggleType] = useState(true);
  const [sourceSummaryHeading, setSourceSummaryHeading] = useState(null)
  const [question, setQuestion] = useState((''))
  const [matrixType, setMatrixType] = useState();

  const [slide, setSlide] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sectionToggle, setSectionToggle] = useState("");
  const [totalSummaryData, setTotalSummaryData] = useState([]);
  const [blockName, setBlockName] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [widgetIsCollapsed, setWidgetIsCollapsed] = useState(true);
  const [widgetChange, setWidgetChange] = useState(defaultWidgetChange)
  const [widgetArray, setWidgetArray] = useState([])

  const [selectFilterValue, setSelectFilterValue] = useState(null)
  const [newSummary, setNewSummary] = useState()
  const [sortingOptions, setSortingOptions] = useState()
  const [selectSortingValue, setSelectSortingValue] = useState("completionHightoLow")


  const childRef = useRef();

  const [sourceData, setSourceData] = useState([]);
  const dashComponent = widgetArray?.find((i) => i.id === widgetChange.id);



  const [boxValues, setBoxValues] = useState({
    crpGroups: "",
    numberOfDistricts: "",
    numberofVillages: "",
    peopleMobilized: "",
    ultraPoorApproved: "",
    didiDropOutCount:"",
    coveredDidis: "",
  });


  const toggleSlide = () => setSlide(!slide);
  const toggleTab = () => {
    setToggleType(!toggleType);
  };

  const setLoaderAction = (action) => {
    dispatch(showLoaderAction(action));
  }

  const getSummary =
    summary && (summary.length > 0 || Object.keys(summary).length > 0)
      ? summaryTransformer({
        activity: widgetChange,
        data: summary
      })
      : {};

  const handleSectionChange = (value) => {
    if (value !== sectionToggle) {
      setIsError(false)
      dispatch(showLoaderAction(true));
    }

    setSectionToggle(value);

  };

  const handleRefChange = (value) => {

    const refId = refobj.find((refname) => refname.value === value);
    const userAuth = JSON.parse(localStorage.getItem("userAuth")) || "";
    const reffValue = userAuth?.referenceId?.find(x => x.name === value)
    setLocalStorage('activeReff', reffValue)
    dispatch(setRefValue(value));
    dispatch(setRoleValue({ roleName: roleName, roleId: refId.id }));

    fetchBoxValues(refId.id);
  };


  const handleMatrixDataChange = (value) => {
    setMatrixType(value);
  };

  const fetchWidgetList = async () => {
    setshowLoader(true)
    try {
      const response = await dashBoardService.fetchActivityList(missionId);
      const resObj = response.data;
      if (resObj?.status === "SUCCESS") {
        const _data = transformWidgetData(resObj.data)
        setWidgetArray(_data)
        setWidgetChange(_data[0])
        setshowLoader(false);
      } else {
        setWidgetArray([])
      }

    } catch (error) {
      catchErrorHandler(error, () => {
        setWidgetArray([])
        setshowLoader(false)
      }, {
        actionType: "SELECT",
        actionStatusType:"ERROR"
      });
    }
  }

  const fetchBoxValues = async (refId) => {
    try {
      dispatch(showLoaderAction(true));
      const payload = {
        "referenceId": refId,
        "referenceName":roleName
    }

      const response = await dashBoardService.getGenericBoxData(payload);
      const resObj = response.data;
      if (resObj?.status === "SUCCESS") {
        setBoxValues({
          numberOfBlocks: resObj?.data?.blocksCount,
          numberOfDistricts: resObj?.data?.districtsCount,
          numberofVillages: resObj?.data?.villagesCount,
          numberOfVo:resObj?.data?.vosCount,
          upcmsCount: resObj?.data?.upcmsCount,
          bpmApprovedDidisCount: resObj?.data?.bpmApprovedDidisCount,
          didiDropOutCount : resObj?.data?.didiDropOutCount,
          coveredDidis: resObj?.data?.bpmApprovedDidisCount - resObj?.data?.didiDropOutCount
        });
        
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
        setBoxValues({});
      }


    } catch (error) {
      catchErrorHandler(error, () => {
        setBoxValues({});
        dispatch(showLoaderAction(false));
      }, {
        actionType: "SELECT",
        actionStatusType:"ERROR"
      });
    }
  };

  const getSummaryData = async (referenceName, id) => {

    setIsError(false)
    dispatch(showLoaderAction(true));
    let payload = {
      "referenceId": id,
      "referenceName": referenceName.toUpperCase(),
      "activityId": widgetChange?.id
    }
    try {
      const response = await dashBoardService.getChartViewData(payload);
      const resObj = response.data;
      if (resObj?.message === "SUCCESS") {
        setSummary(resObj?.data);
      } else {
        setSummary([]);
        setIsError(true)
      }
      dispatch(showLoaderAction(false));
    } catch (error) {
      catchErrorHandler(error, () => {
        setSummary([]);
        setIsError(true)
        dispatch(showLoaderAction(false));

      }, {
        actionType: "SELECT",
        actionStatusType:"ERROR"
      });

    }
  };

  const getSourceData = async (referenceName, id) => {
    dispatch(showLoaderAction(true));
    setIsError(false)
    const payload = {
      "referenceId": id,
      "referenceName": referenceName.toUpperCase(),
      "activityId": widgetChange?.id
    }
    try {
      const res = await dashBoardService.getSourceData(payload);
      const resObj = res.data;
      if (resObj?.message === "SUCCESS") {
        setSourceData(resObj?.data);
        dispatch(showLoaderAction(false));
      } else {
        dispatch(showLoaderAction(false));
        setSourceData([])
       // setIsError(true)
      }
    } catch (error) {
      
      catchErrorHandler(error, () => {
        setSourceData([])
        //setIsError(true)
        dispatch(showLoaderAction(false));

      }, {
        actionType: "SELECT",
        actionStatusType:"ERROR"
      });
    }
  };

  const imgObj = {
    layer,
    mapLayer,
    vo,
    users,
    usericon,

  };

  const boxData = Object.keys(boxValues).length > 0 ? getboxData(boxValues, imgObj, roleName) : [];

  const getBreadCrumbs = (role) => {
    let arr = [];
    if (["National"].includes(role)) {
      arr.push(<div className="arrow">India</div>);
    }
    if (Object.keys(activeGeoFilter?.state).length > 0) {
      arr.push(<div className="arrow">{activeGeoFilter?.state?.name}</div>);
    }
    if (Object.keys(activeGeoFilter?.district).length > 0) {
      arr.push(<div className="arrow">{activeGeoFilter?.district?.name}</div>)
    }
    if (Object.keys(activeGeoFilter?.block).length > 0) {
      arr.push(<div className="arrow">{activeGeoFilter?.block?.name}</div>)
    }

    return arr;
  };




  const exportToCsv = async () => {

    setshowLoader(true)
    const payload = {
      "referenceId": roleValue?.roleId,
      "referenceName": roleValue?.roleName,
      "activityId": widgetChange?.id
    }
    try {
      const res = await dashBoardService.downloadSourceData(payload);
      const url = URL.createObjectURL(res.data);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = res.headers['content-disposition'];
      const parts = contentDisposition.split(';');
      const filenamePart = parts[1];
      const sheetName = filenamePart.split('=')[1].trim();
      const filename = sheetName ? sheetName : `${''}sheet.xlsx`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      // Clean up the URL object
      window.URL.revokeObjectURL(url);
      setshowLoader(false)
    }
    catch (error) {
      catchErrorHandler(error, () => {
        setshowLoader(false)
        setIsError(true)
        dispatch(showLoaderAction(false));

      }, {
        actionType: "SELECT",
        actionStatusType:"ERROR"
      });

    }
  }


  const handleBlockClick = async (blockName, id) => {
    setIsError(false)
    setLoaderAction(true)
    dispatch(setActiveGeoFilters({ ...activeGeoFilter, block: { roleName: 'Block', roleId: id, name: blockName } }))
    dispatch(setRoleValue({ roleName: 'Block', roleId: id }));
    dispatch(setCurrentGeoRef({ roleName: 'Block', roleId: id }));
    try {
      const response = await dashBoardService.getDiagramViewApi(
        "Block",
        id,
        "map"
      );

      const resObj = response.data;
      if (resObj?.status === "SUCCESS") {
        setSummary(resObj?.data?.villages);
        setSummaryMapValue(resObj?.data)
        setSummaryRole('Block')
        setBlockName(blockName)
      }
      else {
        setSummary([]);
        setSummaryMapValue({})
        setIsError(true)
      }
      setLoaderAction(false)

    } catch (error) {
      catchErrorHandler(error, () => {
        setSummary([]);
        setSummaryMapValue({})
        setIsError(true)
      }, {
        actionType: "SELECT",
        actionStatusType:"ERROR"
      });
      setLoaderAction(false)
    }

  }

  const handleSearchAndFilter = (value, type) => {
    let list = JSON.parse(JSON.stringify(summary));

    if (type === 'search') {
      setSelectFilterValue(value)
      const searchedList = searchDoerFilter(value, list)
      if (selectSortingValue) {
        list = doerSubjectSorting(selectSortingValue, searchedList)
      }
      else {
        list = searchedList
      }
    }
    if (type === 'filter') {
      setSelectSortingValue(value)
      const filteredList = doerSubjectSorting(value, list)
      if (selectFilterValue) {
        list = searchDoerFilter(selectFilterValue, filteredList)
      }
      else {
        list = filteredList
      }
    }
    setNewSummary(list)

  }
  const searchDoerFilter = (value, list) => list?.filter(x => x.doerName?.toLowerCase().includes(value?.toLowerCase()))

  const doerSubjectSorting = (value, list) => {
    
    switch (value) {
      case 'doerAtoZ':
        return sortByKey(list, 'doerName', true)
      case 'doerZtoA':
        return sortByKey(list, 'doerName', false)
      case 'subjectLowtoHigh':
        return sortByKey(list, 'subjectCount', true)
      case 'subjectHightoLow':
        return sortByKey(list, 'subjectCount', false)
      case 'completionLowtoHigh':
        return sortByKey(list, 'taskCompletionPercentage', true)
      case 'completionHightoLow':
        return sortByKey(list, "taskCompletionPercentage", false)
      default:
        return []
    }
  }

  const handleFullScreen = () => {
    if (!isFullScreen)
      document.querySelector("body").classList.add("overflowHidden");
    else document.querySelector("body").classList.remove("overflowHidden");
    setIsFullScreen(!isFullScreen);
  };
  const handleBackBtn = async () => {
    switch (summaryRole) {
      case 'State':
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, state: {} }))
        break;
      case 'District':
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, district: {} }))
        break;
      case 'Block':
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, block: {} }))
        break;
      case 'Village':
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, village: {} }))
        break;
      default:
        dispatch(setActiveGeoFilters({ state: {}, district: {}, block: {}, village: {} }))
        break;
    }

    if (summaryRole === 'Block') {
      setIsError(false)
      dispatch(showLoaderAction(true));
      setBlockName("")
      try {
        const response = await dashBoardService.getDiagramViewApi(
          "District",
          districtId,
          "map"
        );

        const resObj = response.data;
        if (resObj?.status === "SUCCESS") {
          setSummary(resObj?.data?.blocks);
          setSummaryMapValue(resObj?.data)
          setSummaryRole('District')
          setBlockName("")

          setDistrictId("")
        }
        else {
          setSummary([]);
          setSummaryMapValue({})
          setIsError(true)
        }
        dispatch(showLoaderAction(false));
      } catch (error) {
        catchErrorHandler(error, () => {
          setSummary([]);
          setSummaryMapValue({})
          setIsError(true)
        }, {
          actionType: "SELECT",
          actionStatusType:"ERROR"
        });
        dispatch(showLoaderAction(false));
      }
    }
    else {

      setSummaryRole('State')
    }
  }

  useEffect(() => {

    fetchBoxValues(refobj[0]?.id || ""); // To -do change for box data
    dispatch(setRefValue(refobj[0]?.value || ""));
    // setReffIdAndRoll({ roleName: roleName, roleId: refobj[0]?.id }) To-do
    dispatch(setRoleValue({ roleName: roleName, roleId: refobj[0]?.id || '' }));
    setSummaryRole(roleName);
    setSectionToggle('Chart View')
    switch (roleName) {
      case "State":
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, state: { roleName: roleName, roleId: refobj[0]?.id, name: refobj[0]?.value } }))
        break;
      case "District":
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, district: { roleName: roleName, roleId: refobj[0]?.id, name: refobj[0]?.value } }))
        break;
      case "Block":
        dispatch(setActiveGeoFilters({ ...activeGeoFilter, block: { roleName: roleName, roleId: refobj[0]?.id, name: refobj[0]?.value } }))
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMission(getMissionById(missionId))
    fetchWidgetList()
  }, [missionId])

  useEffect(() => {
    setSelectFilterValue('')
    setSelectSortingValue('completionHightoLow')

  }, [widgetChange])

  useEffect(() => {
    setSortingOptions([
      { name: 'Task % completion: High to Low', value: 'completionHightoLow' },
      { name: 'Task % completion: Low to High', value: 'completionLowtoHigh' },
      { name: `Assigned ${widgetChange?.subject} Count: High to Low`, value: 'subjectHightoLow' },
      { name: `Assigned ${widgetChange?.subject} Count: Low to High`, value: 'subjectLowtoHigh' },
      { name: `${widgetChange?.doer} Name: A to Z`, value: 'doerAtoZ' },
      { name: `${widgetChange?.doer} Name: Z to A`, value: 'doerZtoA' },
    ])
    setNewSummary(summary)

    

    const list = JSON.parse(JSON.stringify(summary));
    const filteredList = doerSubjectSorting("completionHightoLow", list)
    setNewSummary(filteredList)
  }, [summary])

  useEffect(() => {
    if (['District', 'Block'].includes(summaryRole) && sectionToggle === "Map View")
      setSectionToggle("Summary View")
    else if (['National', 'State'].includes(summaryRole) && sectionToggle === "Summary View")
      setSectionToggle("Map View")
  }, [summaryRole])

  useEffect(() => {
    const sourceTableData = ((Array.isArray(sourceData) && sourceData.length > 0) || sourceData) && widgetChange?.type
      ? getSourceDataMapping(widgetChange, sourceData)
      : {}
    setSourceSummaryHeading(sourceTableData)
  }, [sectionToggle, widgetChange, sourceData])

  if (showLoader) {
    return <PreLoader />;
  }
  const activeReffValue = getLocalStorage('activeReff');
  return (
    <div className="main-page fixed-header">
      <Header />

      <div className="main-section">
        <div className={`content-section ${widgetIsCollapsed ? 'content-section-collapsed' : 'content-section-expanded'}`}>
          <div className="section">
            <div className="upper-dropdown">
              {refobj.length > 1 ? (
                <CustomDropdown
                  placeHolder={referenceValue}
                  showValue={true}
                  options={refobj}
                  onChange={handleRefChange}
                  value={activeReffValue.name}
                />
              ) : (
                <div className="reference-section">
                  <div>
                  <span className="mission-type">{(mission?.name)?.toUpperCase() + ' - '}</span>
                  <span className="reference-name">{getDisplayName()}</span>
                  </div>
                  </div> || ""
              )}
            </div>

            {boxData.length > 0 ? <InfoBox boxData={boxData} page={'ultra-poor'}/> : <FallbackComp />}
          </div>
          {isFullScreen && (
            <div className="map-overlay" onClick={handleFullScreen} />
          )}
          <div className="map-section-outer">
            <div
              className={`${dashComponent?.type === 'map' ? "map-section h550" : "map-section"} ${isFullScreen ? "full-screen-map" : ""}`}
            >
              <div className="map-section-head">
                <div className="map-top-left">
                  {showBack && (
                    <div onClick={handleBackBtn}>
                      <img src={back} width="25px" alt='back-btn' />
                    </div>
                  )}
                  <div className="map-title">{dashComponent?.name}</div>

                  {dashComponent?.description && <div className="info-ibtn" onMouseLeave={() => setBtnTooltip('')} onMouseEnter={() => setBtnTooltip(dashComponent?.description)}>
                    <img src={info} width="17" alt="info-icon" />
                    {btntooltip && <div id="tooltip" className="right">
                      <div className="tooltip-arrow" />
                      <div className="tooltip-label mt5">

                        {btntooltip}

                      </div>
                    </div>}
                  </div>}


                </div>
                <div className="map-top-right">

                  {sectionToggle.includes("Chart") && <div className="map-filter-tab">
                    <><button
                      className={toggleType ? "active" : ""}
                      onClick={() => toggleTab()}
                    >
                      No.
                    </button>

                      <button
                        className={!toggleType ? "active" : ""}
                        onClick={() => toggleTab()}
                      >
                        %
                      </button> <span>of {widgetChange?.subject}</span></>
                  </div>}

                  <div className="left-section">
                    <CustomDropdown
                      placeHolder={sectionToggle}
                      showValue={true}
                      value={sectionToggle}
                      options={sectionToggleOptions(dashComponent?.type, summaryRole)}
                      onChange={handleSectionChange}
                    />
                    <div className="icon-section">
                      {!['Chart View', 'Map View', 'Summary View'].includes(sectionToggle) && (
                        <div style={{ cursor: 'pointer' }} className="map-right-c">
                          <img style={{ cursor: 'pointer' }} onClick={() => setShowExcelCta(!showExcelCta)} src={showExcelCta ? downloadActive : download} width="20" alt="download" />
                          {showExcelCta && <div className="download-excel" onClick={() => exportToCsv()}>Download Excel</div>
                          }                      </div>
                      )}
                      {/* <div className="map-right-r" onClick={handleFullScreen}>
                        <img src={isFullScreen ? minimize : maximize} width="24" alt="maximize" />
                      </div> */}
                    </div>{" "}
                  </div>
                </div>
              </div>


              {!showLoader && <div className="generic-breadcrumb-filter">
                {!['Map View', 'Summary View'].includes(sectionToggle) && <GenericBreadcrumb
                  activityId={widgetChange?.id}
                  referenceValue={referenceValue}
                  setSummary={setSummary}
                  setMapState={setStateName}
                  summaryRole={summaryRole}
                  setSummaryRole={setSummaryRole}
                  setSourceData={setSourceData}
                  percentNumberToggle={toggleType}
                  sourceType={dashComponent?.type}
                  sectionToggle={sectionToggle}
                  roleValue={roleValue}
                  setRoleValue={setRoleValue}
                  setIsError={setIsError}
                  showLoader={showLoader}
                  getSourceData={getSourceData}
                  dashComponent={dashComponent}
                  matrixType={matrixType}
                  getSummaryData={getSummaryData}
                />}
                {sectionToggle.includes("Chart") && <div className="select-filter"> <div className="filter-section">
                  <SearchBar
                    handleOnChange={(e) => { handleSearchAndFilter(e.target.value, 'search') }}
                    customDropdownStyle={true}
                    placeholder={`Search ${widgetChange?.doer}`}
                    value={selectFilterValue} />
                </div>
                  <div className="sorting-section"> <CustomDropdown
                    propWidth='180px'
                    value={selectSortingValue}
                    customDropdownStyle={true}
                    placeHolder="Sort by"
                    onChange={(value) => handleSearchAndFilter(value, 'filter')}
                    options={sortingOptions} />
                  </div>
                </div>
                }
              </div>
              }



              {sectionToggle.includes("Summary") || sectionToggle.includes("Chart") ? (
                <React.Fragment>

                  <div
                    className={
                      slide ? " map-section-body" : " jc-c map-section-body"
                    }
                  >
                    {isError && <FallbackComp className={slide ? "map-inner w60" : "map-inner"}/>}
                    {!isError && <div className={slide ? "map-inner w60 scrolable-chart" : "map-inner scrolable-chart"}>
                      {summary && <HorizontalChartView slide={slide} summary={newSummary} toggleType={toggleType} activity={widgetChange} />}


                      {getSummary?.summaryValues?.length > 0
                        ? (
                          <SummaryData
                            slide={slide}
                            toggleSlide={toggleSlide}
                            summary={getSummary}
                            userRole={roleName}
                            type={dashComponent?.type}
                            roleName={summaryRole}
                          />
                        ) : !isError ? (dashComponent?.type === 'map' && ['District', 'Block'].includes(summaryRole)) && !showLoader ? <div style={{ width: '900px' }}><NoDataFound /></div> : null : <FallbackComp/>}
                    </div>}
                  </div>
                </React.Fragment>
              ) : (
                !isLoading && ( sourceSummaryHeading && !isError ? (
                  <SourceData
                    sourceType={dashComponent?.type}
                    sourceData={sourceSummaryHeading}
                    setSourceData={setSourceData}
                    showLoader={showLoader}
                  />
                ) : <FallbackComp/>
              ))}
            </div>
            {isLeftArrow && <button type="button" className="slick-arrow slick-prev dashboard-button" onClick={() => childRef.current.dashboardPrev()}></button>}
            {isRightArrow && <button type="button" className="slick-arrow slick-next dashboard-button" onClick={() => childRef.current.dashboardNext()}></button>}
          </div>
        </div>
        <div className="slider-section">
          <WidgetSlider
            activityList={widgetArray}
            setIsError={setIsError}
            setWidgetChange={setWidgetChange}
            widgetChange={widgetChange}
            setSlide={setSlide}
            setStateName={setStateName}
            setDistrictName={setDistrictName}
            setToggleType={setToggleType}
            setSummary={setSummary}
            type={dashComponent?.type}
            roleName={roleName}
            setSummaryRole={setSummaryRole}
            setQuestion={setQuestion}
            setShowExcelCta={setShowExcelCta}
            setSourceData={setSourceData}
            setSectionToggle={setSectionToggle}
            ref={childRef}
            setshowLoader={setshowLoader}
            widgetType="generic"
            setWidgetIsCollapsed={setWidgetIsCollapsed}
            widgetIsCollapsed={widgetIsCollapsed}
          />
        </div>
      </div>
    </div>
  );
}

export default GenericDashboard;
