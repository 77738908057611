
const TableColumns = (props) => {
   
    return [
        
        {
            key: "langCode",
            text: `Language Code`,
            isSort: true
        },
        {
            key: "language",
            text: `Language`,
            isSort: true
        }

    ]
}
export default TableColumns;
